.locked {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

html, body {
  background: #fdfdfd;
  color: #212535;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.page-outline {
  border-top: 8px solid #FCECDE;
  border-right: 8px solid #FCECDE;
  border-left: 8px solid #FCECDE;
  height: calc(100vh);
  width: calc(100%);
  position: fixed;
  border-radius: 6px;
}

.container {
  margin: auto;
  width: 1220px;
  max-width: 100%;
  padding: 0 25px;
}

.divider {
  height: 5px;
  background: #FCECDE;
  width: 1160px;
  max-width: 100%;
  margin: auto;
  border-radius: 50%;
}

.main-headshot {
  width: 200px;
  max-width: 50%;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(40, 40, 40, 0.18));
  filter: drop-shadow(5px 5px 5px rgba(40, 40, 40, 0.18));
}

.nav-logo {
  height: 40px;
}

.header-section {
  min-height: 55vh;
  text-align: center;
  margin: auto;
}
.header-section > div {
  width: 600px;
  max-width: 90%;
}

.about-section {
  background: #212535;
  position: relative;
  color: #fdfdfd;
  padding: 50px 0;
  margin: 40px 0;
  border-left: 7px solid #FCECDE;
  border-right: 7px solid #FCECDE;
}

.job-modal-container {
  background: rgba(240, 240, 240, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.job-modal {
  position: relative;
  background: #212535;
  border-radius: 10px;
  height: 80vh;
  width: 1000px;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fdfdfd;
  box-shadow: 0 0 10px 10px rgba(30, 30, 30, 0.2);
}
.job-modal .close-icon {
  width: 30px;
  display: block;
}
.job-modal .job-modal-info {
  height: 100%;
  overflow: scroll;
  width: 100%;
  padding: 100px 18% 100px;
}
.job-modal .fade-div {
  height: 150px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7), transparent);
}
.job-modal .job-logo {
  width: 75px;
}

.work-section {
  margin-top: 50px;
  padding: 10vh 0;
  min-height: 60vh;
  width: 100%;
  position: relative;
}

.work-section-item {
  padding: 20px 20px 100px;
  width: calc(100% - 10px);
  margin: 10px;
  background: rgba(240, 240, 240, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px) saturate(1.7);
  border-radius: 5px;
  position: relative;
}

.work-item-link {
  position: absolute;
  bottom: 15px;
}

.nav {
  margin: auto;
  background: rgba(240, 240, 240, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px) saturate(1.7);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
  padding: 12px 25px;
  z-index: 2;
}

.scroll-arrow {
  height: 20px;
}

.text-yellow {
  color: #FCECDE !important;
}

.text-light {
  color: #5c5c61 !important;
}

.text-dark {
  color: #212535 !important;
}

.text-light-purple {
  color: #fdfdfd !important;
}

.bg-yellow {
  background-color: #FCECDE !important;
}

.bg-light {
  background-color: #5c5c61 !important;
}

.bg-dark {
  background-color: #212535 !important;
}

.bg-light-purple {
  background-color: #fdfdfd !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.flex-3 {
  flex: 3 !important;
}

.flex-4 {
  flex: 4 !important;
}

.flex-5 {
  flex: 5 !important;
}

.m-top-5 {
  margin-top: 5px;
}

.p-top-5 {
  padding-top: 5px;
}

.m-bottom-5 {
  margin-bottom: 5px;
}

.p-bottom-5 {
  padding-bottom: 5px;
}

.m-left-5 {
  margin-left: 5px;
}

.p-left-5 {
  padding-left: 5px;
}

.m-right-5 {
  margin-right: 5px;
}

.p-right-5 {
  padding-right: 5px;
}

.m-top-10 {
  margin-top: 10px;
}

.p-top-10 {
  padding-top: 10px;
}

.m-bottom-10 {
  margin-bottom: 10px;
}

.p-bottom-10 {
  padding-bottom: 10px;
}

.m-left-10 {
  margin-left: 10px;
}

.p-left-10 {
  padding-left: 10px;
}

.m-right-10 {
  margin-right: 10px;
}

.p-right-10 {
  padding-right: 10px;
}

.m-top-15 {
  margin-top: 15px;
}

.p-top-15 {
  padding-top: 15px;
}

.m-bottom-15 {
  margin-bottom: 15px;
}

.p-bottom-15 {
  padding-bottom: 15px;
}

.m-left-15 {
  margin-left: 15px;
}

.p-left-15 {
  padding-left: 15px;
}

.m-right-15 {
  margin-right: 15px;
}

.p-right-15 {
  padding-right: 15px;
}

.m-top-20 {
  margin-top: 20px;
}

.p-top-20 {
  padding-top: 20px;
}

.m-bottom-20 {
  margin-bottom: 20px;
}

.p-bottom-20 {
  padding-bottom: 20px;
}

.m-left-20 {
  margin-left: 20px;
}

.p-left-20 {
  padding-left: 20px;
}

.m-right-20 {
  margin-right: 20px;
}

.p-right-20 {
  padding-right: 20px;
}

.m-top-25 {
  margin-top: 25px;
}

.p-top-25 {
  padding-top: 25px;
}

.m-bottom-25 {
  margin-bottom: 25px;
}

.p-bottom-25 {
  padding-bottom: 25px;
}

.m-left-25 {
  margin-left: 25px;
}

.p-left-25 {
  padding-left: 25px;
}

.m-right-25 {
  margin-right: 25px;
}

.p-right-25 {
  padding-right: 25px;
}

.m-top-30 {
  margin-top: 30px;
}

.p-top-30 {
  padding-top: 30px;
}

.m-bottom-30 {
  margin-bottom: 30px;
}

.p-bottom-30 {
  padding-bottom: 30px;
}

.m-left-30 {
  margin-left: 30px;
}

.p-left-30 {
  padding-left: 30px;
}

.m-right-30 {
  margin-right: 30px;
}

.p-right-30 {
  padding-right: 30px;
}

.m-top-35 {
  margin-top: 35px;
}

.p-top-35 {
  padding-top: 35px;
}

.m-bottom-35 {
  margin-bottom: 35px;
}

.p-bottom-35 {
  padding-bottom: 35px;
}

.m-left-35 {
  margin-left: 35px;
}

.p-left-35 {
  padding-left: 35px;
}

.m-right-35 {
  margin-right: 35px;
}

.p-right-35 {
  padding-right: 35px;
}

.m-top-40 {
  margin-top: 40px;
}

.p-top-40 {
  padding-top: 40px;
}

.m-bottom-40 {
  margin-bottom: 40px;
}

.p-bottom-40 {
  padding-bottom: 40px;
}

.m-left-40 {
  margin-left: 40px;
}

.p-left-40 {
  padding-left: 40px;
}

.m-right-40 {
  margin-right: 40px;
}

.p-right-40 {
  padding-right: 40px;
}

.m-top-45 {
  margin-top: 45px;
}

.p-top-45 {
  padding-top: 45px;
}

.m-bottom-45 {
  margin-bottom: 45px;
}

.p-bottom-45 {
  padding-bottom: 45px;
}

.m-left-45 {
  margin-left: 45px;
}

.p-left-45 {
  padding-left: 45px;
}

.m-right-45 {
  margin-right: 45px;
}

.p-right-45 {
  padding-right: 45px;
}

.m-top-50 {
  margin-top: 50px;
}

.p-top-50 {
  padding-top: 50px;
}

.m-bottom-50 {
  margin-bottom: 50px;
}

.p-bottom-50 {
  padding-bottom: 50px;
}

.m-left-50 {
  margin-left: 50px;
}

.p-left-50 {
  padding-left: 50px;
}

.m-right-50 {
  margin-right: 50px;
}

.p-right-50 {
  padding-right: 50px;
}

.button {
  padding: 8px 15px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  font-family: "Jost";
}
.button.button-primary {
  background-color: #FCECDE;
  color: #212535;
  box-shadow: 2px 2px 0 #5c5c61;
  border: none;
}
.button.button-primary:hover {
  background-color: #f4e5d7;
}
.button.button-primary:active {
  box-shadow: 0 0 0 #5c5c61;
}
.button.button-secondary {
  background-color: transparent;
  padding: 6px 13px;
  border: 2px solid #212535;
  color: #212535;
}
.button.button-secondary.dark {
  border-color: #FCECDE;
  color: #FCECDE;
}
.button.button-secondary.dark:hover {
  border-color: #f4e5d7;
  color: #f4e5d7;
}
.button.button-ghost {
  background-color: transparent;
  color: #212535;
  position: relative;
  transition: all 0.3s;
}
.button.button-ghost:after {
  content: "";
  background: none;
  height: 6px;
  width: 0px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  transition: all 0.3s;
  border-radius: 8px;
}
.button.button-ghost:hover:after {
  content: "";
  background: #212535;
  height: 6px;
  width: 15px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0px;
  transition: all 0.3s;
}
.button.smaller {
  padding: 4px 8px;
}

.lines-art-right {
  position: absolute;
  right: -10%;
  top: 0;
  width: 500px;
}

.lines-art-left {
  position: absolute;
  left: -10%;
  top: 0;
  width: 500px;
}

.skill-box {
  padding: 5px 8px;
  background: #FCECDE;
  color: #212535;
  border-radius: 5px;
  margin: 5px;
  font-family: "Jost";
}

.footer {
  height: 250px;
  width: 100%;
  background: #FCECDE;
  border: 7px solid #FCECDE;
  text-align: center;
}
.footer .footer-logo {
  height: 130px;
  background: #FCECDE;
  padding: 20px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  position: absolute;
}
.footer .footer-info {
  margin: auto;
  width: 450px;
  max-width: 100%;
  justify-content: space-between;
}

.social-logos img {
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.vert {
  height: 60px;
  border-left: 1px solid rgba(253, 253, 253, 0.6);
  margin: 10px;
  position: relative;
}
.vert:after {
  content: "";
  height: 12px;
  width: 12px;
  position: absolute;
  background: white;
  border-radius: 50%;
  left: 0;
  top: 50%;
  transform: translate(-55%, -50%);
  border: 4px solid #212535;
}

.pointer {
  cursor: pointer;
}

.typeform-wrapper {
  width: 100%;
  height: 800px;
}

@font-face {
  font-family: Jost;
  font-weight: normal;
  src: url("../fonts/Jost-Regular.woff") format("woff"), url("../fonts/Jost-Regular.otf") format("opentype"), url("../fonts/Jost-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Recoleta";
  font-weight: normal;
  src: url("../fonts/Recoleta-Medium.otf") format("opentype");
}
h1 {
  font-size: 80px;
  font-family: "Recoleta";
}

h2 {
  font-size: 45px;
  font-family: "Jost";
}

h3 {
  font-size: 25px;
  font-weight: 100;
  font-family: "Jost";
}
h3:after {
  content: " |";
}
h3:before {
  content: "| ";
}

h4 {
  font-size: 20px;
  font-weight: 400;
}

h5 {
  font-size: 16px;
  font-weight: 400;
}

p {
  font-size: 15px;
  font-family: "Jost";
  font-weight: 400;
  margin-bottom: 0;
}

a {
  font-family: "Jost";
  color: #FCECDE;
  text-decoration: none;
}
a:visited {
  color: #FCECDE;
}

@media (max-width: 1000px) {
  .typeform-wrapper {
    height: 400px;
  }
}

