@each $name, $hex in $colors-list {
  .text-#{$name} {
      color:$hex!important;
  }
}

@each $name, $hex in $colors-list {
  .bg-#{$name} {
     background-color:$hex!important;
  }
}

@each $name, $number in $num-list {
  .flex-#{$name} {
     flex:$number!important;
  }
}

@each $name, $number in $m-p-num-list {
    @each $direction in (top, bottom, left, right) {
        .m-#{$direction}-#{$number} {
            margin-#{$direction} : $number + px;
        }
        .p-#{$direction}-#{$number} {
            padding-#{$direction} : $number + px;
        }
    }
}
