@import "_variables";

.locked {
    margin: 0; height: 100%; overflow: hidden
}
html, body {
    background: $light-purple;
    color:$dark-text;
    margin:0;
    padding:0;
    overflow-x:hidden;
}
.page-outline {
    border-top:8px solid $yellow;
    border-right:8px solid $yellow;
    border-left:8px solid $yellow;
    height:calc(100vh);
    width:calc(100%);
    position:fixed;
    border-radius: 6px;
}
.container {
    margin:auto;
    width:1220px;
    max-width: 100%;
    padding:0 25px;
}

.divider {
    height:5px;
    background:$yellow;
    width:1160px;
    max-width: 100%;
    margin:auto;
    border-radius:50%;
}

.main-headshot {
    width:200px;
    max-width:50%;
    -webkit-filter: drop-shadow(5px 5px 5px rgba(40, 40, 40, .18));
    filter: drop-shadow(5px 5px 5px rgba(40, 40, 40, .18));
}

.nav-logo {
    height:40px;
}

.header-section {
    min-height:55vh;
    text-align:center;
    margin:auto;
    & > div {
        width:600px;
        max-width:90%;
    }
}

.about-section {
    background:$dark-text;
    position:relative;
    color:$light-purple;
    padding:50px 0;
    margin:40px 0;
    border-left: 7px solid $yellow;
    border-right: 7px solid $yellow;
}
.job-modal-container {
    background:rgba($dark-purple, .2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    height:100vh;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    z-index:2;
}
.job-modal {
    position:relative;
    background:$dark-text;
    border-radius:10px;
    height:80vh;
    width:1000px;
    max-width:100%;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color:$light-purple;
    box-shadow: 0 0 10px 10px rgba(30, 30, 30, 0.2);
    & .close-icon {
        width:30px;
        display:block;
    }
    .job-modal-info {
        height: 100%;
        overflow: scroll;
        width: 100%;
        padding: 100px 18% 100px;
    }
    .fade-div {
        height: 150px;
        width: 100%;
        position: absolute;
        bottom: 0;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.7), transparent);
    }
    .job-logo {
        width:75px;
    }
}
.work-section {
    margin-top:50px;
    padding:10vh 0;
    min-height:60vh;
    width:100%;
    position:relative;
}
.work-section-item {
    padding:20px 20px 100px;
    width:calc(100% - 10px);
    margin:10px;
    background:rgba($dark-purple, .5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(20px) saturate(1.7);
    border-radius:5px;
    position:relative;
}

.work-item-link {
    position:absolute;
    bottom:15px;
}

.nav {
    margin:auto;
    background:rgba($dark-purple, .5);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(20px) saturate(1.7);
    position:fixed;
    left:50%;
    transform:translateX(-50%);
    border-radius:30px;
    padding:12px 25px;
    z-index:2
}
.scroll-arrow {
    height:20px;
}
@import "_functions";
@import "_components";

.skill-box {
    padding:5px 8px;
    background:$yellow;
    color:$dark-text;
    border-radius:5px;
    margin:5px;
    font-family:"Jost"
}

.footer {
    height:250px;
    width:100%;
    background:$yellow;
    border: 7px solid $yellow;
    text-align:center;
    & .footer-logo {
        height: 130px;
        background: $yellow;
        padding: 20px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        position: absolute;
    }
    & .footer-info {
        margin:auto;
        width:450px;
        max-width:100%;
        justify-content:space-between;
    }
}
.social-logos {
& img {
        height:20px;
        margin:0 10px;
        cursor:pointer
    }
}

.vert {
    height:60px;
    border-left:1px solid rgba($light-purple, .6);
    margin:10px;
    position:relative;
    &:after {
        content:"";
        height:12px;
        width:12px;
        position:absolute;
        background:white;;
        border-radius:50%;
        left:0;
        top:50%;;
        transform:translate(-55%, -50%);
        border:4px solid $dark-text;
    }
}
.pointer {
    cursor:pointer
}

.typeform-wrapper {
    width:100%;
    height:800px;
}

@import "_typography";

@media (max-width: "1000px") {
    .typeform-wrapper {
        height: 400px;
    }
}
