.button {
    padding: 8px 15px;
    border-radius:3px;
    cursor: pointer;
    transition: all .3s;
    font-family:"Jost";
    &.button-primary {
        background-color:$yellow;
        color:$dark-text;
        box-shadow: 2px 2px 0 $light-text;
        border:none;
        &:hover {
            background-color:$yellow-hover;
        }
        &:active {
            box-shadow: 0 0 0 $light-text;
        }
    }
    &.button-secondary {
        background-color:transparent;
        padding: 6px 13px;
        border: 2px solid $dark-text;
        color:$dark-text;
        &.dark {
            border-color:$yellow;
            color:$yellow;
        &:hover {
            border-color:$yellow-hover;
            color:$yellow-hover;
        }
        }
    }
    &.button-ghost {
        background-color:transparent;
        color:$dark-text;
        position:relative;
        transition: all .3s;
        &:after {
            content:"";
            background:none;
            height:6px;
            width:0px;
            position:absolute;
            left:50%;
            transform:translateX(-50%);
            top:0;
            transition: all .3s;
            border-radius:8px;
        }
        &:hover {
            &:after {
                content:"";
                background:$dark-text;
                height:6px;
                width:15px;
                position:absolute;
                left:50%;
                transform:translateX(-50%);
                top:0px;
                transition: all .3s;
            }
        }
    }
    &.smaller {
        padding: 4px 8px;
    }
}

.lines-art-right {
    position:absolute;
    right:-10%;
    top:0;
    width:500px;
}
.lines-art-left {
    position:absolute;
    left:-10%;
    top:0;
    width:500px;
}
