$light-purple: #fdfdfd;
$dark-purple: mix($light-purple, black, 95%);
$light-text:#5c5c61;
$dark-text:#212535;;
$yellow: #FCECDE;


// hover

$yellow-hover: mix($yellow, black, 97%);

$colors-list: (
    "yellow": $yellow,
    "light": $light-text,
    "dark": $dark-text,
    "light-purple": $light-purple,
);

$num-list: (
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5
);

$m-p-num-list: (
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    2%: 25,
    30: 30,
    35: 35,
    40: 40,
    45: 45,
    50: 50
)

           

