@font-face {
  font-family: Jost;
  font-weight: normal;
  src: url("../fonts/Jost-Regular.woff") format("woff"),
    url("../fonts/Jost-Regular.otf") format("opentype"),
    url("../fonts/Jost-Regular.ttf") format("ttf");
}
@font-face {
  font-family: "Recoleta";
  font-weight: normal;
  src: url("../fonts/Recoleta-Medium.otf") format("opentype");
}

h1 {
font-size:80px;
font-family:"Recoleta";
//text-transform:uppercase;
}
h2 {
    font-size:45px;
    font-family:"Jost";
}
h3 {
    font-size:25px;
    font-weight:100;
    font-family:"Jost";
    &:after {
        content: " |";
    }
    &:before {
        content: "| ";
    }
}
h4 {
    font-size:20px;
    font-weight:400;
}
h5 {
    font-size:16px;
    font-weight:400;
}

p {
    font-size:15px;
    font-family: "Jost";
    font-weight:400;
    margin-bottom:0
}
a {
 font-family:"Jost";
 color:$yellow;
 text-decoration:none;
    &:visited {
        color:$yellow
    }
}
